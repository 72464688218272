export default class Entity {
  constructor(
    id,
    about,
    address,
    areasExpertise,
    category,
    email,
    license,
    logo,
    logs,
    media,
    name,
    newLogo,
    nuit,
    status,
    telephone,
    telephone2,
    views,
  ) {
    this.id = id
    this.about = about
    this.address = address
    this.areasExpertise = areasExpertise
    this.category = category
    this.email = email
    this.license = license
    this.logo = logo
    this.logs = logs
    this.media = media
    this.name = name
    this.newLogo = newLogo
    this.nuit = nuit
    this.status = status
    this.telephone = telephone
    this.telephone2 = telephone2
    this.views = views
  }
}
