<template>
  <v-container class="fill-height" fluid>
    <v-row class="d-flex justify-end">
      <v-btn v-model="edit" color="primary" dark @click="changeEdit()">
        <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.title }}
      </v-btn>

      <!-- <v-switch  :label="$t('Editprofile')" hide-details class="py-0 my-0"></v-switch> -->
    </v-row>
    <edit-profile v-if="edit && entity" @switch="changeEdit" />
    <profile-details v-else-if="entity" />
  </v-container>
</template>

<script>
import { ENTITY_QUERY } from "./../graphql/Query";
import { mapActions } from "vuex";
import EditProfile from "./../components/EditProfile";
import ProfileDetails from "./../components/ProfileDetails";
export default {
  name: "Profile",
  components: {
    EditProfile,
    ProfileDetails,
  },
  data: () => ({
    edit: false,
    entity: {},
    btntext: "",
  }),
  apollo: {
    entity: {
      query: ENTITY_QUERY,
    },
  },
  watch: {
    entity: function (val) {
      this.setEntityState(val);
    },
  },
  computed: {
    tab() {
      return this.edit
        ? { title: this.$t("institutionprofile"), icon: "mdi-eye" }
        : { title: this.$t("Editprofile"), icon: "mdi-pencil" };
    },
  },
  methods: {
    ...mapActions({
      setEntityState: "entity/setEntity",
    }),
    changeEdit() {
      this.edit = !this.edit;
    },
  },
};
</script>